<template>
  <div class="meetQR-container">
    <div
      ref="liveInfoGuzhuanyiContainerRef"
      class="live-info-guzhuanyi-container"
      :style="$tools.getImageBg('info.bg')"
    >
      <div class="live-info-guzhuanyi-title_banner">
        <img
          :src="$tools.getImageContent('infoFiles.banner')"
          class="live-info-guzhuanyi-title-img"
          mode="widthFix"
          alt=""
        />
      </div>
      <div class="live-info-guzhuanyi-content-block">
        <div
          class="live-info-guzhuanyi-title-text global-color"
          :style="$tools.getColor()"
        >
          {{ meetingInfo?.meet_schedule?.name }}
        </div>
        <div class="live-info-guzhuanyi-date-block">
          <div
            class="live-info-guzhuanyi-date-title-text"
            :style="$tools.getBackgroundColor()"
          >
            会议时间
          </div>
          <div class="live-info-guzhuanyi-date-content-block">
            <div class="live-info-guzhuanyi-date-content-left">
              <div class="live-info-guzhuanyi-date-year">
                <img
                  :src="$tools.getImageContent('infoFiles.right-sj')"
                  mode="widthFix"
                  class="live-info-guzhuanyi-right-date-img"
                  alt=""
                /><span>{{
                  this.$tools.getDate(
                    this.meetingInfo.meet_info.start_time,
                    "yyyy"
                  )
                }}</span>
              </div>
              <div
                class="live-info-guzhuanyi-date-month"
                :style="$tools.getColor()"
              >
                {{
                  this.$tools.getDate(
                    this.meetingInfo.meet_info.start_time,
                    "MM/dd"
                  )
                }}
              </div>
            </div>
            <div
              class="live-info-guzhuanyi-date-content-middle"
              :style="$tools.getBackgroundColor()"
            ></div>
            <div class="live-info-guzhuanyi-date-content-right">
              <div class="live-info-guzhuanyi-date-week-icon global-center">
                <img
                  :src="$tools.getImageContent('infoFiles.date')"
                  mode="widthFix"
                  class="live-info-guzhuanyi-date-icon"
                  alt=""
                />
                {{
                  this.weeks[
                    this.$tools
                      .getFormatTime(this.meetingInfo.meet_info.start_time)
                      .getDay()
                  ]
                }}
              </div>
              <div class="live-info-guzhuanyi-date-time global-center">
                <img
                  :src="$tools.getImageContent('infoFiles.time')"
                  mode="widthFix"
                  class="live-info-guzhuanyi-date-icon"
                  alt=""
                />

                {{
                  this.$tools.getDate(
                    this.meetingInfo.meet_info.start_time,
                    "hh:mm"
                  )
                }}~{{
                  this.$tools.getDate(
                    this.meetingInfo.meet_info.end_time,
                    "hh:mm"
                  )
                }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="live-info-guzhuanyi-info-block global-center"
          :style="$tools.getColor()"
        >
          <img
            :src="$tools.getImageContent('infoFiles.left-arrow')"
            mode="widthFix"
            class="live-info-guzhuanyi-info-arraw"
            alt=""
          />
          <div class="live-info-guzhuanyi-theme-title">
            {{ meetingInfo?.meet_schedule?.theme_name }}
          </div>
          <img
            :src="$tools.getImageContent('infoFiles.right-arrow')"
            mode="widthFix"
            class="live-info-guzhuanyi-info-arraw"
            alt=""
          />
        </div>
        <div class="live-info-guzhuanyi-theme">
          《 {{ meetingInfo?.meet_info?.name }}》
          <!-- {{ meetingInfo?.meet_schedule?.theme_content }} -->
        </div>
        <div
          class="live-info-guzhuanyi-info-block global-center"
          :style="$tools.getColor()"
        >
          <img
            :src="$tools.getImageContent('infoFiles.left-arrow')"
            mode="widthFix"
            class="live-info-guzhuanyi-info-arraw"
            alt=""
          />
          <div class="live-info-guzhuanyi-theme-title">
            {{ meetingInfo?.meet_schedule?.info_name }}
          </div>
          <img
            :src="$tools.getImageContent('infoFiles.right-arrow')"
            mode="widthFix"
            class="live-info-guzhuanyi-info-arraw"
            alt=""
          />
        </div>
        <div class="live-info-guzhuanyi-theme">
          {{ meetingInfo?.meet_schedule?.doctor_info }}
        </div>
        <div
          class="live-info-guzhuanyi-content-row live-info-guzhuanyi-table global-border"
          :style="$tools.getBorderColor()"
        >
          <div
            class="live-info-guzhuanyi-table_title_tr"
            :style="$tools.getBackgroundColor()"
          >
            <div
              class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table-td-title"
              :style="$tools.getImageBg('info.td_bg')"
            >
              <!-- <img :src="$tools.getImageContent('infoFiles.time')" class="live-info-guzhuanyi-table-td-title-img" alt=""/> -->
              时间
            </div>
            <div
              class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table-td-title"
            >
              内容
            </div>
            <div
              class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table-td-title"
            >
              讲者
            </div>
          </div>
          <div
            class="live-info-guzhuanyi-table_content_tr live-info-guzhuanyi-table_content_tr"
            v-for="(item, key) in meetingInfo?.meet_schedule?.timeline || []"
            :key="key"
          >
            <div
              class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table_td_content global-border global-color"
              :style="`${$tools.getBorderColor()}`"
            >
              {{ `${item.start_time}-${item.end_time}` }}
            </div>
            <div
              class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table_td_content global-border global-color"
              :style="`${$tools.getBorderColor()}`"
            >
              {{ item.content }}
            </div>
            <div
              class="live-info-guzhuanyi-table_td global-center live-info-guzhuanyi-table_td_content global-border global-color"
              :style="`${$tools.getBorderColor()}`"
            >
              {{ item.doctor }}
            </div>
          </div>
        </div>
        <div class="live-info-guzhuanyi-QRCode global-color">
          <div
            class="live-info-guzhuanyi-QRCode-block global-border"
            :style="$tools.getBorderColor()"
          >
            <img
              class="live-info-guzhuanyi-QRCode_img"
              :src="QRUrl || $tools.getImageContent('infoFiles.code')"
              alt=""
            />
          </div>
          <div class="live-info-guzhuanyi-prompt">
            扫码进入直播间与专家亲密互动
            <div>长按识别二维码</div>
            <!-- {{ meetingInfo?.meet_schedule?.prompt }} -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcode";
import dom2Image from "@/common/dom2Image";
import html2canvas from "html2canvas";
import uploads from "@/common/uploads";
export default {
  components: {},
  props: {
    content: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      weeks: [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ],
      meetingInfo: {},
      QRUrl: "",
    };
  },
  async created() {
    this.getPoster(this.content);
  },
  methods: {
    getPoster(item) {
      this.$tips.loading();

      QRCode.toDataURL(
        `${this.$config.host}/#/QRjump?meetId=${item.id}`,
        { errorCorrectionLevel: "H", width: 500, margin: 1 },
        async (err, QRUrl) => {
          this.QRUrl = await uploads.uploadBase64({
            file: QRUrl,
            url: "https://files.medflying.com/file_system/filesbase64",
            tempData: {
              project: "guzhuanyizhoukan",
              type: "placard",
            },
          });

          let url = this.$tools.getURL(this.$urls.live.meetInfo, {
            meet_number: item.meet_number,
          });
          this.$axios
            .get(url)
            .then((res) => {
              this.$tips.loading().close();
              this.meetingInfo = res.data.data;
              this.$nextTick(async () => {
                await this.getWeekImgBase64URL();
                // 在DOM加载完成后执行的操作
              });
            })
            .catch(() => {
              this.$tips.loading().close();
            });
        }
      );
    },
    doClose() {
      this.showPopup = false;
      this.$emit("getPoster", {});
    },
    //生成长图
    createPlacardImg(scale = 2) {
      console.log("1 :>> ", this.$refs.liveInfoGuzhuanyiContainerRef);
      return new Promise((resolve) => {
        setTimeout(() => {
          // dom2Image
          //   .draw(this.$refs.liveInfoGuzhuanyiContainerRef, {
          //     bgcolor: "#fff",
          //     devicePixelRatio: scale,
          //   })
          //   .then(function (dataUrl) {
          //     resolve(dataUrl);
          //   });
          // #capture 就是我们要获取截图对应的 DOM 元素选择器

          html2canvas(this.$refs.liveInfoGuzhuanyiContainerRef, {
            useCORS: true, // 【重要】开启跨域配置
            width: this.$refs.liveInfoGuzhuanyiContainerRef.clientWidth,
            height: this.$refs.liveInfoGuzhuanyiContainerRef.clientHeight - 1, //减1像素，去掉白边
            scale: scale,
            scrollY: 0,
            scrollX: 0,
            allowTaint: true, // 允许跨域图片
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/jpeg", 1.0);
            resolve(imgData);
          });
        }, 300);
      });
    }, //获取长图链接
    async getWeekImgBase64URL() {
      try {
        let loading = this.$tips.loading();
        //生成标清图
        // let img = await this.createPlacardImg();
        // let tempData = {
        //   project: "guzhuanyizhoukan",
        //   type: "placard",
        // };
        // let url = await uploads.uploadBase64({
        //   file: img,
        //   url: "https://files.medflying.com/file_system/filesbase64",
        //   tempData,
        // });
        //生成高清图
        let HDImg = await this.createPlacardImg(5);
        let HDTempData = {
          project: "guzhuanyizhoukan",
          type: "placard",
        };
        let HDUrl = await uploads.uploadBase64({
          file: HDImg,
          url: "https://files.medflying.com/file_system/filesbase64",
          HDTempData,
        });
        // this.data.weekly_picture_high = HDUrl;
        this.poster = HDUrl;
        console.log("url :>> ", HDUrl, this.poster);
        this.$emit("getMeetPoster", HDUrl);
        loading.close();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" >
.live-info-guzhuanyi-container {
  // height: 100%;
  overflow: auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // display: none;
}

.live-info-guzhuanyi-title_banner {
  width: 100%;
}

.live-info-guzhuanyi-title-img {
  display: block;
  width: 100%;
  height: auto;
}

.live-info-guzhuanyi-content-block {
  width: 100%;
  flex: 1 0 0;
  background-repeat: no-repeat;
  padding: 0 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.live-info-guzhuanyi-title-text {
  font-size: 22px;
  text-align: center;
  font-weight: 700;
  margin-bottom: 15px;
}

.live-info-guzhuanyi-date-block {
  width: 75%;
  // box-shadow: ;
}

.live-info-guzhuanyi-date-title-text {
  text-align: center;
  line-height: 30px;
  font-size: 15px;
  color: #fff;
  border-top-left-radius: 7.5px;
  border-top-right-radius: 7.5px;
}

.live-info-guzhuanyi-date-content-block {
  display: flex;
  color: #000;
  margin-bottom: 22.5px;
  background: #fff;
  height: 60px;
  border-bottom-left-radius: 7.5px;
  border-bottom-right-radius: 7.5px;
  padding: 7.5px 0;
  box-shadow: 0px 1px 2px 1px rgba(242, 103, 74, 0.2);
  border: 1px solid #f2674a;
}

.live-info-guzhuanyi-date-content-left {
  flex: 4 0 0;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.live-info-guzhuanyi-date-year {
  font-size: 13px;
  height: 15px;
  line-height: 15px;
  display: flex;
  align-items: center;
}

.live-info-guzhuanyi-date-month {
  font-size: 24px;
  font-weight: 700;
}

.live-info-guzhuanyi-date-content-middle {
  width: 1px;
  height: 100%;
  margin: 0 7.5px;
}

.live-info-guzhuanyi-date-content-right {
  flex: 6 0 0;
  font-size: 13px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.live-info-guzhuanyi-right-date-img {
  width: 11.25px;
  // height:10.36px;
  margin-right: 3.75px;
}

.live-info-guzhuanyi-date-week-icon {
}

.live-info-guzhuanyi-date-time {
}

.live-info-guzhuanyi-date-icon {
  width: 15px;
  margin-right: 3.75px;
}
.live-info-guzhuanyi-info-block {
  font-weight: 700;
  font-size: 14px;
}

.live-info-guzhuanyi-info-arraw {
  width: 18.75px;
}

.live-info-guzhuanyi-theme-title {
  margin: 0 7.5px;
  font-size: 14px;
}

.live-info-guzhuanyi-info-block {
}

.live-info-guzhuanyi-theme {
  color: #000;
  margin: 7.5px 0 18.75px;
  font-size: 14px;
}

.live-info-guzhuanyi-content-row {
  margin-top: 13.125px;
  width: 100%;
}

.content_lable_key {
  font-weight: 600;
}

.content_doctor_row {
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  word-break: break-all;
}

.content_doctor_row_name {
  margin-right: 30px;
}

.live-info-guzhuanyi-table {
  border: none;
  margin-bottom: 15px;
}

.live-info-guzhuanyi-table_title_tr {
  display: flex;
  width: 100%;
  height: 37.5px;
  border-top-left-radius: 11.25px;
  border-top-right-radius: 11.25px;
}

.live-info-guzhuanyi-table_content_tr {
  display: flex;
  width: 100%;
  align-items: center;
}

.live-info-guzhuanyi-table_content_tr:nth-child(2n) {
  background: rgb(240, 240, 240);
}

.live-info-guzhuanyi-table_content_tr:nth-child(2n-1)
  .live-info-guzhuanyi-table_td_content,
.live-info-guzhuanyi-table_content_tr
  .live-info-guzhuanyi-table_td_content:last-child {
  border-right: none;
}

.live-info-guzhuanyi-table_td_content {
  border: none;
}

.live-info-guzhuanyi-table_td {
  min-height: 37.5px;
  flex: 1 0 0;
  padding: 0 1.875px;
  text-align: center;
  color: #000;
  font-size: 14px;
}

.live-info-guzhuanyi-table-td-title {
  background-repeat: no-repeat;
  // color: #fff;
  font-weight: 600;
  padding: 13.5px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.live-info-guzhuanyi-table-td-title-img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.live-info-guzhuanyi-QRCode {
  width: 100%;
  height: 210px;
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.live-info-guzhuanyi-QRCode_img {
  width: 130px;
  height: 130px;
}
.live-info-guzhuanyi-QRCode-block {
  flex: 150px 0 0;
  height: 150px;
  border-radius: 5px;
  margin-right: 10px;
  padding: 10px;
  border-width: 1px;
  border-style: solid;
}
.live-info-guzhuanyi-prompt {
  font-size: 12px;
  color: #000;
  line-height: 24px;
}
.meetQR-main {
  width: 100%;
}
.meetQR-img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.meetQR-group-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: #ccc;
}
.meetQR-group-btn .meetQR-tips {
  margin-top: 10px;
}
.meetQR-container .popup_main {
  overflow: auto;
  height: 100%;
  width: 100%;
  border-radius: 0;
  padding: 0;
}
.meetQR-content .popup_main {
  height: 95%;
  width: 80%;
}
</style>