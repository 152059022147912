<template>
  <div class="meet-info-container full-screen">
    <div
      class="meet-info-main"
      :style="`${isHaveCourseware ? '' : 'display:flex;height:100%;'}`"
    >
      <div class="meet-info-title global-color">
        {{ isHaveCourseware ? "基本信息修改" : "会议基本信息" }}
      </div>
      <div class="meet-info-row">
        <div
          class="isWatch"
          v-if="
            !(meetInfo.status == 0 || meetInfo.status == 4 || !meetInfo.status)
          "
        ></div>
        <div class="meet-info-row-key">
          <span v-for="text in '会议日期'" :key="text">{{ text }}</span>
        </div>
        <span class="meet-info-space">：</span>
        <div class="meet-info-row-value">
          <el-date-picker
            class="elDatePicker"
            v-model="baseData.date"
            :disabled-date="verifyDate"
            value-format="YYYY-MM-DD"
            type="date"
            @change="changeDate"
            clearable
            :editable="false"
            placeholder="请选择日期"
          >
            <!-- @panelChange="handlePanelChange" -->
            <template #default="cell">
              <div class="cell" :class="{ current: cell.isCurrent }">
                <span class="text">{{ cell.text }}</span>
                <span v-if="isHoliday(cell)" class="holiday">已满</span>
              </div>
            </template></el-date-picker
          >
        </div>
      </div>
      <div class="meet-info-row">
        <div
          class="isWatch"
          v-if="
            !(meetInfo.status == 0 || meetInfo.status == 4 || !meetInfo.status)
          "
        ></div>
        <div class="meet-info-row-key">
          <span v-for="text in '会议开始时间'" :key="text">{{ text }}</span>
        </div>
        <span class="meet-info-space">：</span>
        <div class="meet-info-row-value basic_info_time">
          <el-select
            v-model.trim="baseData.startHour"
            @change="changeTime"
            placeholder="时"
          >
            <el-option
              v-for="item in config.startHours"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <span class="meet-info-row-symbol">:</span>
          <el-select
            v-model.trim="baseData.startMinute"
            @change="changeTime"
            class="global-input"
            placeholder="分"
          >
            <el-option
              v-for="item in config.minutes"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </div>
      </div>
      <div class="meet-info-row">
        <div
          class="isWatch"
          v-if="
            !(meetInfo.status == 0 || meetInfo.status == 4 || !meetInfo.status)
          "
        ></div>
        <div class="meet-info-row-key">
          <span v-for="text in '会议主题'" :key="text">{{ text }}</span>
        </div>
        <span class="meet-info-space">：</span>
        <div class="meet-info-row-value" style="padding: 10px 0">
          <!-- style=" font-weight: 400; font-size: 0.433333rem; color: #606060;
          line-height: 30px; margin-left: 20px; " :style="{ color:
          data.meet_topics ? '#606060' : '#c0c4cc' }" -->
          <!-- {{ data.meet_topics ? data.meet_topics : "请选择课件" }} -->
          <el-input
            type="textarea"
            v-model.trim="data.meet_topics"
            placeholder="请选择课件"
            :autosize="{ minRows: 1 }"
          >
          </el-input>
        </div>
      </div>
      <div class="meet-info-row" v-if="job_name == '主任医师'">
        <div
          class="isWatch"
          v-if="
            !(meetInfo.status == 0 || meetInfo.status == 4 || !meetInfo.status)
          "
        ></div>
        <div class="meet-info-row-key">
          <span v-for="text in '支付方式'" :key="text">{{ text }}</span>
        </div>
        <span class="meet-info-space">：</span>
        <div class="meet-info-row-value" style="padding: 10px 0">
          <el-select v-model.trim="baseData.reduce_pay" placeholder="请选择">
            <el-option label="本场会议按主任医师(3000)支付" :value="0" />
            <el-option label="本场会议按副主任医师(2000)支付" :value="1" />
          </el-select>
        </div>
      </div>
      <div class="meet-info-title meet-info-courseware global-color">
        {{ isHaveCourseware ? "已选课件" : "课件列表" }}
      </div>
      <div class="meet-info-courseware-list" v-if="!isHaveCourseware">
        <ListItem @getStorageDate="getStorageDate"></ListItem>
      </div>
      <PPTPlayer
        :data="coursewareData.courseware_info || meetInfo.courseware_info"
        v-if="isHaveCourseware && meetInfo.courseware_info?.length"
      ></PPTPlayer>
      <div
        class="meet-info-courseware-oneself"
        v-if="isHaveCourseware && meetInfo.courseware_info?.length == 0"
      >
        <div class="meet-info-courseware-oneself-load">
          <el-icon class="is-loading">
            <Loading />
          </el-icon>
          课件解析中请稍等
        </div>
      </div>
      <div
        class="meet-info-button-group"
        v-if="isHaveCourseware && meetInfo.courseware_info?.length"
      >
        <CustomButton
          class="meet-info-button"
          @click="changeCourseare"
          v-if="
            !meetInfo.id ||
            meetInfo.audit_status == 30 ||
            meetInfo.status === 0 ||
            meetInfo.status == 4
          "
          >更换课件</CustomButton
        >
        <CustomButton class="meet-info-button" @click="downLoadCourseware"
          >下载课件</CustomButton
        >
        <CustomButton
          class="meet-info-button"
          @click="uploadCourseware"
          v-if="
            (!meetInfo.id ||
              meetInfo.audit_status == 30 ||
              meetInfo.status == 0 ||
              meetInfo.status == 4) &&
            false
          "
          >上传课件</CustomButton
        >
      </div>
      <div class="meet-info-btn">
        <div
          class="meet-info-submit global-background"
          @click="openPay"
          v-if="
            job_name == '主任医师' &&
            (meetInfo.status == 2 || meetInfo.status == 3) &&
            meetInfo.audit_status != 30 &&
            !meetInfo.sign_name
          "
        >
          调整支付方式
        </div>
        <!-- 待召开status == 0 超时未召开status == 4  能改-->
        <div
          v-if="
            (!meetInfo.cancel_status || meetInfo.cancel_status == 30) &&
            (meetInfo.status == 0 || meetInfo.status == 4 || !meetInfo.status)
          "
          class="meet-info-submit global-background"
          @click="doCommit"
        >
          {{ meetInfo.id ? "修改" : "提交" }}
        </div>
        <div class="meet-info-submit global-background" @click="goBack" v-else>
          返回
        </div>
        <!-- 正式会议进行中 结束 不让取消 其他均可取消 -->
        <div
          class="meet-info-submit meet-info-cancel"
          v-if="
            (((meetInfo.finish_test == 1 &&
              (meetInfo.status == 0 || meetInfo.status == 4)) ||
              meetInfo.finish_test == 0) &&
              meetInfo.cancel_status == 0) ||
            meetInfo.cancel_status == 30
          "
          @click="goCancel"
        >
          申请取消会议
        </div>
      </div>
    </div>
    <input
      type="file"
      v-show="false"
      ref="resetFile"
      v-if="resetFile"
      @change="changeFile"
    />
  </div>
  <meetQR
    class="meetQR"
    v-if="status"
    :content="meetingInfo"
    @getMeetPoster="getMeetPoster"
  >
  </meetQR>
  <popup
    :msg="popMsg"
    v-if="showPopup"
    @doClose="showPopup = false"
    @doSure="doSure"
  >
    <div class="powerpopup_main">
      <div class="powerpopup_row">
        <div class="powerpopup_row_value" style="margin: 20px 0">
          <el-radio-group v-model="reduce_pay">
            <el-radio label="本场会议按主任医师(3000)支付" :value="0" />
            <el-radio label="本场会议按副主任医师(2000)支付" :value="1" />
          </el-radio-group>
        </div>
      </div>
    </div>
  </popup>
  <popup
    :msg="popMsgReason"
    v-if="showPopupReason"
    @doClose="showPopupReason = false"
    @doSure="doSureCancel"
  >
    <div class="powerpopup_main" style="width: 100%">
      <div class="powerpopup_row" style="display: flex; align-items: center">
        <div class="powerpopup_row_key" style="flex: 70px 0 0">
          {{ popMsgReason.title == "会议时间修改" ? "修改理由" : "申请理由" }} :
        </div>
        <div class="powerpopup_row_value" style="margin: 20px 0; flex: 1 0 0">
          <el-input
            :autosize="{ minRows: 2 }"
            v-model="cancel_reason"
            :placeholder="`请输入不少于8个字的${
              popMsgReason.title == '会议时间修改' ? '修改理由' : '申请理由'
            }`"
            type="textarea"
          ></el-input>
        </div>
      </div>
    </div>
  </popup>
</template>
<script>
import { ElDatePicker, ElInput, ElOption, ElSelect } from "element-plus";
import ListItem from "@/components/page/courseware/components/list";
import PPTPlayer from "@/components/player/PPTPlayer";
import CustomButton from "@/components/unit/CustomButton";
import uploads from "@/common/uploads";
import { Loading } from "@element-plus/icons";
import meetQR from "./meetQR.vue";
import popup from "../../unit/Popup.vue";

export default {
  name: "basicInfo",
  components: {
    ElDatePicker,
    ElOption,
    ElInput,
    ElSelect,
    ListItem,
    PPTPlayer,
    CustomButton,
    Loading,
    meetQR,
    popup,
  },
  props: {
    info: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      currKey: "", //弹窗 时间修改 / 取消回应
      cancel_reason: "",
      reduce_pay: "",
      popMsg: {
        align: "center",
        title: "支付方式",
        btngroup: { sure: "确认修改", close: "返回" },
      },
      popMsgReason: {
        align: "center",
        title: "申请取消会议",
        // isNotFullClose: true,
        btngroup: { sure: "确认提交" },
      },
      showPopupReason: false,
      showPopup: false,
      poster: "",
      status: false,
      pptSlides: [],
      meetingInfo: {},
      meetInfo: {},
      data: {
        meet_topics: "",
        meet_start_time: "",
        meet_end_time: "",

        user_id: 0,
        literature_id: 0,
      },
      baseData: {
        date: "",
        startHour: "",
        startMinute: "",
        endHour: "",
        endMinute: "",
      },
      meetNumWarning: [],
      config: {
        day: 24 * 60 * 60 * 1000,
        space: 3, //几天内
        startHours: [],
        endHours: [],
        minutes: [],
        // minutes: ["00", "30"],
        area: [10, 21],
      },
      isHaveCourseware: true,
      loading: false,
      resetFile: true,
      coursewareData: {},
      timer: null,
      job_name: "",
    };
  },
  async created() {
    this.coursewareData = this.$tools.getStorage("coursewareData") || {};
    let meetData = this.$tools.getStorage("meetData");
    if (meetData?.date) {
      this.baseData = meetData;
    }
    if (this.$route.query.meet_id) {
      await this.loadData();
    } else {
      this.initData();
    }
    this.isHaveCourseware = Boolean(
      this.coursewareData?.literature_id || this.meetInfo?.literature_id
    );
    if (this.coursewareData?.literature_id) {
      this.data = { ...this.data, ...this.coursewareData };
      this.meetInfo.courseware_info = this.coursewareData.courseware_info;
    } else {
      this.data = {
        ...this.data,
        literature_id: this.meetInfo.literature_id,
        meet_topics: this.meetInfo.meet_topics,
        courseware_info: this.meetInfo.courseware_info,
      };
    }
    console.log(
      "this.meetInfo.courseware_info :>> ",
      this.meetInfo.courseware_info
    );
    this.data.user_id = this.$route.query.user_id || 0;
    this.getDoctorInfo();
  },
  mounted() {},
  methods: {
    async getDoctorInfo() {
      let url = this.$tools.getURL(this.$urls.sale.userInfo, {
        id: this.data.user_id,
      });
      await this.$axios
        .get(url, { "user-id": this.data.user_id })
        .then((res) => {
          this.job_name = res.data.job_name;
        })
        .catch(() => {});
    },
    async loadData() {
      let url = this.$tools.getURL(this.$urls.meet.meetInfo, {
        id: this.$route.query.meet_id,
      });
      await this.$axios
        .get(url)
        .then((res) => {
          this.meetInfo = res.data;

          this.meet_start_time = res.data.meet_start_time;
          this.literature_id = res.data.literature_id;
          if (res.data.courseware_info?.length == 0) {
            this.getCoursewareInfo(res.data.literature_id);
          }
          this.baseData.reduce_pay = res.data.reduce_pay;
          this.initData();
        })
        .catch((err) => {
          if (err.err.response.status == 422) {
            this.$router.push({
              path: "/login",
              query: {
                ...this.$route.query,
              },
            });
          }
        });
    },
    changeCourseare() {
      let userInfo = this.$tools.getStorage("userLogin", localStorage) || {};
      if (userInfo?.is_sale == 0) {
        this.getStorageDate();
        this.$router.push({
          path: "/courseware/list",
          query: { ...this.$route.query, is_sale: this.$route.query.is_sale },
        });
        return;
      } else {
        this.isHaveCourseware = !this.isHaveCourseware;
      }
    },
    initData() {
      this.data.meet_start_time = this.meetInfo.meet_start_time;
      this.data.meet_end_time = this.meetInfo.meet_end_time;
      if (this.$route.query.meet_id) {
        this.initBaseData();
      }
      this.initHours(this.baseData.date ? false : true);
      this.getMeetNumWarning();
    },
    initBaseData() {
      let [startDate, startHour, startMinute] = this.$tools
        .getDate(this.data.meet_start_time, "yyyy-MM-dd hh:mm")
        .split(/[\s:]/);
      let [endHour, endMinute] = this.$tools
        .getDate(this.data.meet_end_time, "hh:mm")
        .split(":");
      this.baseData.startHour = startHour;
      this.baseData.startMinute = startMinute;
      this.baseData.endHour = endHour;
      this.baseData.endMinute = endMinute;
      this.baseData.date = startDate;
    },
    initHours(status = false) {
      let hour, startIndex, minute;
      this.config.startHours = [];
      console.log("this.isMoreThanMeetDate() :>> ", this.isMoreThanMeetDate());
      if (!this.isMoreThanMeetDate()) {
        hour = Number(this.$tools.getDate("", "hh"));
        minute = Number(this.$tools.getDate("", "mm"));
        startIndex = hour > this.config.area[0] ? hour : this.config.area[0];
        if (minute > 30) {
          startIndex += 1;
        }
        if (!this.isToday()) {
          startIndex = 23;
        }
        console.log(
          1111111111,
          startIndex,
          hour,
          this.config.area,
          hour > this.config.area[0]
        );
        console.log(
          "status && this.baseData.startHour :>> ",
          status,
          this.baseData
        );
        if (status && this.baseData.startHour) {
          let startTime = this.mergetTime();
          if (this.$tools.getTime(startTime) < Date.now()) {
            this.clearBaseData();
          }
        }
      } else {
        console.log(" this.config.area :>> ", this.config.area);
        [startIndex] = this.config.area;
      }
      console.log(11111111111, startIndex, hour, this.isMoreThanMeetDate());

      for (let index = startIndex; index <= this.config.area[1]; index++) {
        this.config.startHours.push(this.$tools.getTimeText(index));
      }
    },
    clearBaseData() {
      this.baseData.date = "";
      this.baseData.startHour = "";
      this.baseData.startMinute = "";
      this.baseData.endHour = "";
      this.baseData.endMinute = "";
    },
    isToday() {
      return (
        this.baseData.date ==
        this.$tools.getDate(
          this.$tools.getTime(this.$tools.getDate()) +
            this.config.day * this.config.space
        )
      );
    },
    isMoreThanMeetDate() {
      return (
        this.$tools.getTime(this.baseData.date) >
        this.$tools.getTime(this.$tools.getDate()) +
          this.config.day * this.config.space
      );
    },
    mergetTime(status = false) {
      if (
        !(
          this.baseData.date &&
          this.baseData.startHour &&
          this.baseData.startMinute
        ) &&
        !status
      )
        return "";
      return `${this.baseData.date} ${this.baseData.startHour}:${
        this.baseData.startMinute || "00"
      }:00`;
    },
    changeDate() {
      this.initHours(false);
    },
    changeTime() {
      console.log("this.baseData.startHour :>> ", this.baseData.startHour);
      if (this.baseData.startHour) {
        this.config.minutes = ["00", "30"];
        if (this.baseData.startHour == this.config.area[1]) {
          this.config.minutes = ["00"];
        }
      }
      if (!this.baseData.startHour) return;
      if (!this.baseData.startMinute) {
        this.baseData.startMinute = "00";
      }

      this.data.meet_start_time = this.mergetTime();
      this.data.meet_end_time = this.$tools.getDate(
        this.$tools.getTime(this.data.meet_start_time) + 110 * 60 * 1000,
        "yyyy-MM-dd hh:mm:ss"
      );
      this.initBaseData();
    },
    verifyDate(date) {
      if (
        this.meetNumWarning.includes(this.$tools.getDate(date, "yyyy-MM-dd"))
      ) {
        return true;
      }
      let hour = Number(this.$tools.getDate(null, "hh"));
      let minute = Number(this.$tools.getDate(null, "mm"));

      if (
        hour == this.config.area[1] &&
        minute >= 30 &&
        this.$tools.getDate(date) ==
          this.$tools.getDate(
            this.$tools.getTime(new Date()) +
              this.config.day * this.config.space
          )
      )
        return true;
      return hour > this.config.area[1]
        ? this.$tools.getTime(date) <=
            Date.now() + this.config.day * this.config.space
        : this.$tools.getTime(date) <=
            Date.now() + this.config.day * (this.config.space - 1);
    },
    verifyData() {
      if (!this.baseData.date) {
        this.$tips.error({ text: "请选择开始日期" });
        return false;
      }
      if (!this.baseData.startHour) {
        this.$tips.error({ text: "请选择开始时间" });
        return false;
      }

      // 两小时内 不让修改       会议超时 超时未召开状态 审核失败 修改为72小时后

      if (this.meetInfo.status == 0) {
        if (
          this.meet_start_time &&
          this.$tools.getTime(this.meet_start_time) <
            Date.now() + 2 * 60 * 60 * 1000
        ) {
          return this.$tips.error({
            text: "根据项目要求，距离预定时间2小时内不再支持修改会议",
          });
        }

        //   当前时间2小时后 可改课件 时间不改 只改课件
        if (
          this.meet_start_time == this.data.meet_start_time &&
          this.$tools.getTime(this.meet_start_time) >
            Date.now() + 2 * 60 * 60 * 1000 &&
          this.literature_id != this.data.literature_id
        ) {
          return true;
        }
      }
      //时间改变 遵循72 小时
      if (
        this.$tools.getTime(this.mergetTime()) <
        Date.now() + this.config.day * this.config.space
      ) {
        if (this.meetInfo.id) {
          this.$tips.error({
            text: "根据项目合规要求，请选择72小时后的时间",
          });
        } else {
          this.$tips.error({
            text: "根据项目合规要求，请选择72小时后的时间",
          });
        }
        return false;
      }

      if (this.data.courseware_info && !this.data.meet_topics) {
        if (!this.data.meet_topics) {
          this.$tips.error({ text: "请输入会议主题" });
          return false;
        }
      }
      if (!this.data.meet_topics) {
        this.$tips.error({ text: "请选择会议主题" });
        return false;
      }

      if (
        this.job_name == "主任医师" &&
        this.baseData.reduce_pay === undefined
      ) {
        this.$tips.error({ text: "请选择支付方式" });
        return false;
      }

      this.data.meet_start_time = this.mergetTime();
      this.data.meet_end_time = this.$tools.getDate(
        this.$tools.getTime(this.data.meet_start_time) + 110 * 60 * 1000,
        "yyyy-MM-dd hh:mm:ss"
      );
      if (this.baseData.reduce_pay == 0 || this.baseData.reduce_pay == 1) {
        this.data.reduce_pay = this.baseData.reduce_pay;
      }
      return true;
    },
    doCommit() {
      if (this.loading || !this.verifyData()) return;
      this.$route.query.meet_id ? this.doUpdate() : this.doCreate();
    },
    doCreate() {
      let loading = this.$tips.loading();
      this.loading = true;
      let url = this.$urls.meet.create;
      if (this.$store.state.common.userId) {
        url = this.$urls.meet.doctorCreate;
      }
      this.$axios
        .post(url, {
          ...this.data,
        })
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$tools.setStorage("coursewareData", {});
            this.$tools.setStorage("meetData", {});
            this.meetingInfo = {
              id: res.data.meet_id,
              meet_number: res.data.meet_number,
            };
            loading.close();
            this.returnBack();
            // this.status = true;
          }, 1000);
        })
        .catch(() => {
          loading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    doUpdate() {
      this.currKey = "patchMeet";
      if (
        this.meet_start_time &&
        this.meet_start_time != this.data.meet_start_time
      ) {
        this.showPopupReason = true;
        this.popMsgReason = {
          align: "center",
          title: "会议时间修改",
          isNotFullClose: true,
          btngroup: { sure: "确认提交" },
        };
      } else {
        this.doMeetpatch();
      }
    },
    doMeetpatch() {
      let url = this.$urls.meet.meetPatch;
      if (this.$store.state.common.userId) {
        url = this.$urls.meet.meetUserPatch;
      }
      let loading = this.$tips.loading();
      this.loading = true;
      this.$axios
        .patch(url, {
          ...this.data,
          id: this.meetInfo.id,
          user_id: this.meetInfo.user_id,
          change_time_reason: this.cancel_reason,
        })
        .then((res) => {
          setTimeout(() => {
            this.loading = false;
            this.$tools.setStorage("coursewareData", {});
            this.$tools.setStorage("meetData", {});
            this.meetingInfo = {
              id: res.data.meet_id,
              meet_number: res.data.meet_number,
            };
            // this.status = true;
            loading.close();
            this.returnBack();
          }, 1000);
        })
        .catch((err) => {
          loading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    //取消会议
    goCancel() {
      this.showPopupReason = true;
      this.popMsgReason = {
        align: "center",
        title: "申请取消会议",
        // isNotFullClose: true,
        btngroup: { sure: "确认提交" },
      };
    },
    async doSureCancel() {
      //会议时间修改
      if (this.currKey == "patchMeet") {
        if (this.cancel_reason == "" || this.cancel_reason.length < 8)
          return this.$tips.error({ text: "请输入不少于8个字的修改理由" });
        this.doMeetpatch();
        return;
      }
      if (this.cancel_reason == "" || this.cancel_reason.length < 8)
        return this.$tips.error({ text: "请输入不少于8个字的申请理由" });
      let url = this.$tools.getURL(this.$urls.meet.applyCancel, {
        id: this.$route.query.meet_id,
        cancel_reason: this.cancel_reason,
      });
      let loading = this.$tips.loading();
      await this.$axios
        .get(url)
        .then((res) => {
          this.$tips.success({ text: "申请成功" });
          this.returnBack();
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    getMeetPoster(poster) {
      this.poster = poster;
      let url = this.$urls.meet.addPoster;
      let loading = this.$tips.loading();
      this.$axios
        .patch(url, {
          meet_id: this.meetingInfo.id,
          meet_poster: this.poster,
        })
        .then((res) => {
          setTimeout(() => {
            loading.close();
            if (this.$route.query.form) {
              this.$router.push({
                path: this.$route.query.form,
                query: {
                  id: this.$route.query.user_id,
                },
              });
              return;
            }
            if (this.data.id) {
              this.$router.push({
                path: "/adminInfo",
                query: {
                  id: this.$route.query.user_id,
                },
              });
              return;
            } else {
              this.$router.push({
                path: "/doctorInfo",
              });
              return;
            }
          }, 1000);
        })
        .catch((err) => {
          loading.close();
        });
    },
    goBack() {
      this.$router.push({
        path: this.$route.query.form,
        query: {
          id: this.$route.query.user_id,
        },
      });
    },

    downLoadCourseware() {
      this.getStorageDate();
      window.open(
        this.coursewareData.courseware_url || this.meetInfo.courseware_url
      );
      // window.location.href =
      //   this.coursewareData.courseware_url || this.meetInfo.courseware_url;
      // this.$tips.success({ text: "下载成功" });
    },
    getStorageDate() {
      this.$tools.setStorage("meetData", this.baseData);
    },
    uploadCourseware() {
      this.$refs.resetFile.click();
      // this.$tips.success({ text: "上传成功" });
    },
    async changeFile(e) {
      let file = e.target.files[0];
      this.resetFile = false;
      this.$nextTick(() => {
        this.resetFile = true;
      });
      if (!file) return;
      let loading = this.$tips.loading();
      this.loading = true;
      let res = await uploads.uploadFile({
        file,
        reg: "pptx|ppt|pdf",
        key: "files",
      });
      if (!res) {
        this.loading = false;
        loading.close();
      }
      let courseware_url = res.data[0];
      this.$axios
        .post(this.$urls.literature.uploadCourseware, {
          courseware_url,
          meet_id: this.$route.query.meet_id || 0,
        })
        .then((res) => {
          this.data.literature_id = res.data.literature_id;
          this.data.meet_topics = res.data.courseware_title;
          this.meetInfo.courseware_info = [];
          if (this.timer) {
            clearTimeout(this.timer);
            this.timer = null;
          }
          setTimeout(() => {
            this.getCoursewareInfo(res.data.literature_id);
            this.loading = false;
            loading.close();
          }, 1000);
        })
        .catch((err) => {
          loading.close();
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        });
    },
    async getCoursewareInfo(literature_id) {
      let url = this.$tools.getURL(this.$urls.literature.coursewareInfo, {
        literature_id: literature_id,
      });
      await this.$axios
        .get(url)
        .then((res) => {
          console.log("res.data.length :>> ", res.data.length);
          if (res.data.length > 0) {
            this.isHaveCourseware = true;
            this.meetInfo.courseware_info = res.data;
            if (this.timer) {
              clearTimeout(this.timer);
              this.timer = null;
              console.log("this.timer :>> ", this.timer);
            }
          } else {
            this.timer = setTimeout(() => {
              this.getCoursewareInfo(literature_id);
            }, 1000 * 60);
          }
        })
        .catch((err) => {});
    },
    getYM(now, type) {
      let month = now.getMonth() + 1; //获取当前月
      let year = now.getFullYear(); // 获取年份
      if (type == "start") {
        if (month == 1) {
          month = 12;
          year--;
        } else {
          month--;
        }
      }
      if (type == "end") {
        if (month == 12) {
          month = 1;
          year++;
        } else {
          month++;
        }
      }
      month = month < 10 ? `0${month}` : month;
      return `${year}-${month}`;
    },
    getMeetNumWarning(visible) {
      let time = "";
      if (visible) {
        time = new Date(visible);
      } else {
        time = this.meetInfo.date ? new Date(this.meetInfo.date) : new Date();
      }
      let url = this.$tools.getURL(this.$urls.live.meetNumWarning, {
        start_time: this.getYM(time, "start"),
        end_time: this.getYM(time, "end"),
      });
      // console.log("url :>> ", url);
      this.$axios
        .get(url)
        .then((res) => {
          this.meetNumWarning = res.data;
        })
        .catch((err) => {});
    },
    isHoliday({ dayjs }) {
      return this.meetNumWarning.includes(dayjs.format("YYYY-MM-DD"));
    },
    handlePanelChange(visible) {
      this.getMeetNumWarning(visible);
    },
    //支付方式
    openPay() {
      this.reduce_pay = this.meetInfo.reduce_pay;
      this.showPopup = true;
    },
    doSure() {
      let loading = this.$tips.loading();
      this.$axios
        .patch(this.$urls.sale.patchPay, {
          id: this.$route.query.meet_id,
          reduce_pay: this.reduce_pay,
        })
        .then((res) => {
          this.$tips.success({ text: "修改成功" });
          loading.close();
          this.returnBack();
        })
        .catch((err) => {
          loading.close();
          this.showPopup = false;
        });
    },

    returnBack() {
      if (this.$route.query.form) {
        this.$router.push({
          path: this.$route.query.form,
          query: {
            id: this.$route.query.user_id,
          },
        });
        return;
      }
      if (this.data.id) {
        this.$router.push({
          path: "/adminInfo",
          query: {
            id: this.$route.query.user_id,
          },
        });
        return;
      } else {
        this.$router.push({
          path: "/doctorInfo",
        });
        return;
      }
    },
  },
  beforeUnmount() {
    // this.$tools.setStorage("coursewareData", {});
    // this.$tools.setStorage("meetData", {});
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  },
};
</script>
<style>
.meet-info-container {
  width: 100%;
  position: relative;
  justify-content: center;
  padding: 21px;
  overflow: auto;
}

.meet-info-container-bg {
  background: #434343;
  opacity: 0.8;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.meet-info-main {
  position: relative;
  background: #ffffff;
  display: flex;
  flex-direction: column;
}

.meet-info-title {
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  padding: 9px 0;
  border-bottom: 1px dashed #ccc;
}

.meet-info-row {
  display: flex;
  align-items: center;
  /* padding: 9px 0; */
  border-bottom: 1px dashed #ccc;
  position: relative;
}

.meet-info-row-key {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 83px;
  font-weight: 400;
  font-size: 13px;
  color: #606060;
}

.meet-info-row-value {
  flex: 1 0 0;
  margin-left: 10px;
  display: flex;
  color: #221815;
  align-items: center;
  /* min-height: 40px; */
}
.meet-info-space {
  font-size: 13px;
}

.meet-info-btn {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
}
.meet-info-main .el-input__inner {
  padding-left: 10px;
}
.meet-info-container .el-textarea__inner {
  line-height: 1.5;
  border: none !important;
  padding: 0 10px !important;
}
.meet-info-main .el-select,
.meet-info-main .el-date-editor,
.meet-info-main .el-input,
.meet-info-main input {
  border: none;
}

.meet-info-main .el-input__prefix {
  display: none;
}
.meet-info-submit {
  width: 198px;
  /* height: 26px; */
  text-align: center;
  line-height: 26px;
  border-radius: 14px;
  color: #fff;
  font-size: 14px;
  margin-top: 10px;
}
.meet-info-cancel {
  background: #ccc;
}
.meet-info-submit .icon-img {
  width: 11px;
  height: 14px;
  margin-right: 5px;
}

.meet-info-row-symbol {
  font-size: 16px;
  margin: 0 10px;
}

.meet-info-row-value .el-date-editor--date {
  width: 100%;
}
.meet-info-courseware {
  margin-top: 20px;
}
.meet-info-courseware-list {
  flex: 1 0 0;
  overflow: hidden;
  margin-top: 10px;
}
.meet-info-button-group {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
}
.meet-info-button {
  width: 90px !important;
  height: 26px;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.meet-info-courseware-oneself {
  background: #fcd6cc;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-bottom: 100%;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.meet-info-courseware-oneself-load {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.meet-info-courseware-oneself-load .is-loading {
  font-size: 50px;
  margin-bottom: 10px;
}
.loading_button {
  font-size: 2rem;
  color: #126de3;
  animation: round 1s infinite;
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(90deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  75% {
    -webkit-transform: rotate(270deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.meet-info-container .meet-info-courseware-list .courseware-item-container {
  background: #e5e5e5 !important;
}
.meetQR {
  position: fixed;
  top: 0;
  overflow: auto;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.meet-info-container .popup_main {
  height: 100%;
  overflow: auto;
}
.cell {
  height: 30px;
  padding: 3px 0;
  box-sizing: border-box;
}
.el-date-table__row .disabled .cell {
  background: #f5f7fa;
  color: #c0c4cc;
}
.cell .text {
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  line-height: 24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
}
.cell.current .text {
  background: #f26649;
  color: #fff;
}

.cell .holiday {
  font-weight: bold;
  font-size: 10px;
  color: #f26649;
  /* background: #f26649; */
  position: absolute;
  border-radius: 50%;
  bottom: -13px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 1;
}
.el-date-picker .disabled {
  background: #f5f7fa !important;
}
.meet-info-container .el-select__wrapper {
  flex: 1 0 0;
}
.isWatch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
.powerpopup_row_value .el-textarea__inner {
  border: 1px solid #ccc !important;
}
</style>